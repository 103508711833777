@import '../../../../scss/theme-bootstrap';

.tabbed-block {
  &__header,
  &__sub-header {
    text-align: center;
    line-height: 1;
  }
  &__header {
    margin: 25px 0 5px;
    @media #{$medium-up} {
      margin-top: 35px;
    }
  }
  &__sub-header {
    letter-spacing: 0.8px;
    font-family: $akzidenz;
    font-size: 13px;
    @media #{$medium-up} {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 12px;
    }
  }
  &__tabs {
    display: flex;
    justify-content: center;
    margin: 20px 0 30px;
    @media #{$medium-up} {
      margin: 22px 0;
    }
    .tabbed-block__tab {
      display: flex;
      align-items: center;
      padding: 0 15px;
      border-#{$rdirection}: 1px solid $color-navy;
      height: 12px;
      &-label {
        padding: 0;
        &::after,
        &::before {
          position: absolute;
          #{$ldirection}: -9999px;
        }
      }
      &:last-child {
        border: none;
      }
      &-radio {
        &:checked + .tabbed-block__tab-label {
          font-weight: bold;
        }
      }
    }
  }
  &__tab {
    &-label-pc {
      display: none;
      @media #{$medium-up} {
        display: block;
      }
    }
    &-label-mobile {
      display: block;
      @media #{$medium-up} {
        display: none;
      }
    }
  }
  &__content {
    margin-bottom: 20px;
    .tabbed-block__content-item {
      display: none;
      &[aria-hidden='false'] {
        display: block;
      }
      .elc-product-image-badge {
        #{$ldirection}: 15px;
        top: 15px;
        line-height: 1;
        margin: 0;
        @media #{$medium-up} {
          #{$ldirection}: 0;
          top: 0;
        }
      }
    }
    .sd-product-grid {
      .sd-grid-skeleton-items {
        display: flex;
        overflow-x: hidden;
        @media #{$medium-up} {
          display: grid;
          overflow-x: unset;
        }
        .elc-grid-item-product {
          flex: 0 0 100vw;
        }
      }
      min-height: auto;
      .elc-product {
        &-image-section {
          position: relative;
          margin-bottom: 14px;
          @media #{$medium-up} {
            margin-bottom: 10px;
          }
        }
        &-price-row-wrapper {
          display: flex;
          height: 18px;
        }
        &-quick-view-button-wrapper {
          @include ternary(
            $text-direction == rtl,
            transform,
            translate(50%, 0),
            translate(-50%, -50%)
          );
          position: absolute;
          width: 100%;
          top: 50%;
          height: 33px;
          #{$ldirection}: 50%;
          .elc-product-quick-view-button {
            display: block;
            width: auto;
            letter-spacing: 1.25px;
            padding: 0;
            top: auto;
            @media #{$medium-up} {
              display: none;
              min-width: 132px;
            }
            &:hover {
              display: block;
            }
          }
        }
        &-price-range-row-wrapper {
          margin: 0;
        }
        &-image-badge-wrapper {
          width: 100%;
          margin-bottom: 0;
          top: 15px;
          #{$ldirection}: 15px;
          @media #{$medium-up} {
            top: 10px;
            #{$ldirection}: 10px;
          }
        }
        &-badges-wrapper {
          position: absolute;
          width: 100%;
        }
        &-badge-wrapper {
          top: 15px;
          #{$ldirection}: 15px;
          position: absolute;
          @media #{$medium-up} {
            padding-#{$ldirection}: 0;
            margin: 0;
            top: 10px;
            #{$ldirection}: 10px;
          }
          .elc-product-badge {
            font-size: 12px;
            font-weight: bold;
            word-spacing: 3px;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin: 0;
            line-height: 1em;
          }
          & + .elc-product-image-badge-wrapper {
            position: absolute;
            top: 42px;
            #{$ldirection}: 15px;
            @media #{$medium-up} {
              top: 32px;
              #{$ldirection}: 10px;
            }
            .elc-product-image-badge {
              top: 0;
              #{$ldirection}: 0;
            }
          }
        }
        &-subheader,
        &-sub-display-name {
          @include headline--senary;
          font-family: $optimaregular, $optimalight;
          color: $color-navy;
          margin: 4px 0 0;
          line-height: 25px;
          overflow: hidden;
          font-size: 21px;
          @media #{$medium-up} {
            font-size: 15px;
            line-height: 20px;
          }
        }
        &-display-name-link {
          @include headline--quinary;
          font-family: $optimaregular, $optimalight;
          color: $color-navy;
          position: relative;
          z-index: 1;
          margin: 0;
          line-height: 1.1;
          @media #{$medium-up} {
            line-height: 24px;
          }
          &:hover {
            text-decoration: none;
          }
        }
        &-rating-wrapper {
          order: 2;
          display: inline;
          .elc-rating {
            .elc-average-rating-text {
              display: none;
            }
            .elc-reviews-number {
              font-size: 15px;
              font-family: $optimaregular;
              color: $color-navy;
            }
            .elc-read-reviews {
              display: none;
            }
          }
        }
        &-display-name-wrapper {
          padding: 0 0 12px;
        }
        &-name-wrapper,
        &-display-name-wrapper {
          display: block;
          margin: 0;
        }
        &-description-wrapper,
        &-short-description-wrapper {
          line-height: 20px;
          color: $color-navy;
          font-family: $akzidenz;
          font-size: 15px;
        }
      }
      .elc-product-price-range,
      .elc-price-formatted {
        color: $color-navy;
        font-family: $akzidenz;
        font-size: 15px;
        font-weight: normal;
        line-height: 1.1;
        font-weight: bold;
      }
      .elc-price-formatted {
        &-wrapper {
          align-self: center;
          &:last-child {
            @include swap_direction(margin, 0 0 0 15px);
          }
          &:first-child {
            margin: 0;
          }
          .elc-product-price {
            color: $color-red;
          }
        }
      }
      .elc-product-carousel-container {
        .elc-slider-arrow-wrapper {
          width: 22px;
          height: 41px;
          z-index: 1;
          &.slick-prev {
            #{$ldirection}: 0;
            @media #{$xxlarge-up} {
              #{$ldirection}: -25px;
            }
          }
          &.slick-next {
            #{$rdirection}: 0;
            transform: unset;
            @media #{$xxlarge-up} {
              #{$rdirection}: -25px;
            }
            [dir='rtl'] & {
              #{$rdirection}: auto;
              #{$ldirection}: 0;
            }
          }
          .elc-product-image-badge {
            @media #{$medium-up} {
              max-width: 68px;
            }
          }
          &:before {
            content: '';
            display: inline-block;
            background: $color-medium-gray;
            height: 100px;
            position: absolute;
            width: 45px;
            top: -30px;
            #{$ldirection}: -14px;
            opacity: 0.25;
          }
        }
        .elc-img:not([src]) {
          font-size: 0;
        }
        .elc-product-carousel-slider-view
          > .elc-slider-view-wrapper
          > .slick-slider
          > .elc-slider-arrow-wrapper {
          .elc-icon {
            position: absolute;
            transform: unset;
            width: 34px;
            height: 34px;
            display: block;
            top: 5px;
          }
          .elc-slider-arrow-left {
            @include svg-icon-inline-mask('arrow-left.svg');
          }
          .elc-slider-arrow-right {
            @include svg-icon-inline-mask('arrow-right.svg');
            #{$rdirection}: 3px;
          }
        }
        .slick-slider {
          margin: 0;
          padding-#{$rdirection}: 15%;
          touch-action: pan-y;
          @media #{$medium-up} {
            padding: 0;
          }
        }
        .slick-slide {
          padding-#{$rdirection}: 15px;
          margin: 0;
          @media #{$medium-up} {
            padding: 0 14px;
          }
          &:hover {
            .elc-product-quick-view-button {
              display: block;
            }
          }
        }
        .slick-list {
          overflow: visible;
          @media #{$medium-up} {
            overflow: hidden;
          }
        }
        .slick-track {
          padding: 0;
          margin: 0 auto;
        }
        .slick-arrow {
          top: 26%;
          @media #{$medium-up} {
            top: 30%;
          }
        }
      }
      .elc-product-shades-count-wrapper:not([role='link']) {
        @include swap_direction(padding, 12px 0 0 20px);
        @media #{$medium-up} {
          padding: 14px 0 0;
        }
      }
      .elc-product-short-description-wrapper {
        margin: 0;
      }
      .elc-short-description-container {
        padding: 0 0 12px;
        @media #{$medium-up} {
          padding: 0 0 14px;
        }
      }
      .elc-shade-swatches-wrapper {
        padding: 0 0 12px;
        @media #{$medium-up} {
          padding: 0 0 8px;
        }
      }
      .elc-main-content-section {
        margin-#{$ldirection}: 20px;
        @media #{$medium-up} {
          margin-#{$ldirection}: 0;
        }
        & > .elc-carousel-section-wrapper {
          display: block;
        }
        & > *,
        & + .elc-footer-content-section {
          display: none;
        }
      }
      .slick-active {
        .elc-main-content-section {
          & > *,
          & + .elc-footer-content-section {
            display: block;
          }
        }
      }
      .elc-rating {
        margin: 0;
      }
      .elc-rating-component {
        margin: 0 0 12px;
      }
      .elc-slider-view-wrapper,
      .elc-product-carousel-container {
        padding: 0;
        margin: 0;
      }
      .elc-product-brief {
        .slick-arrow {
          display: none;
        }
        .slick-slide {
          padding: 0;
          @media #{$medium-up} {
            margin: 0 4px;
          }
        }
        .slick-slider {
          padding: 0;
        }
        .slick-list {
          overflow: hidden;
        }
        .elc-product-shade-picker {
          .slick-slide {
            .elc-shade-image {
              margin: 0;
              border-width: 1px;
              cursor: pointer;
              display: inline-block;
              height: 25px;
              margin: 1px;
              width: 25px;
              border: none;
              background-color: transparent;
              @media #{$medium-up} {
                border-width: 2px;
                height: 100%;
                max-height: 23px;
                max-width: 23px;
                min-height: 18px;
                min-width: 18px;
                width: 100%;
              }
              &:hover,
              &-selected {
                .elc-absolute-shade-wrapper {
                  box-shadow: 0 0 0 1px $color-navy;
                  border-radius: 50%;
                }
              }
            }
          }
          .slick-arrow {
            display: block;
            height: 45px;
            width: 9px;
            top: -8px;
            &::before {
              display: none;
            }
            & + .slick-list {
              @include swap_direction(margin, 0 14px 0 11px);
              margin: 0 13px;
            }
            &.slick-prev {
              #{$ldirection}: -4px;
              top: 4px;
            }
            &.slick-next {
              #{$rdirection}: 0;
              top: 4px;
              transform: unset;
              [dir='rtl'] & {
                #{$rdirection}: auto;
                #{$ldirection}: 0;
              }
            }
          }
          .elc-single-shade-wrapper {
            padding: 0;
            justify-content: flex-start;
            @media #{$medium-up} {
              padding-bottom: 3px;
            }
            .elc-shade-icon {
              width: 24px;
              height: 24px;
            }
            p {
              font-family: inherit;
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
